import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "tailwindcss/tailwind.css";
import Link from "next/link";
import Image from "next/image";
import { Autoplay, Pagination, Navigation, Mousewheel } from "swiper/modules";

const HomeBanner = ({ bannArray }) => {
  const settings = {
    modules: [Autoplay, Pagination, Navigation, Mousewheel],
    direction: "horizontal",
    mousewheel: {
      thresholdTime: 500,
      forceToAxis: true,
    },
    grabCursor: true,
    thumbs: true,
    pagination: { clickable: true },
    navigation: false,
    loop: true,
    autoplay: {
      delay: 3000,
      pauseOnMouseEnter: true,
    },
    speed: 500,
    slidesPerView: 1,
  };

  return (
    <div className="w-full mx-auto home-banner">
      <div className="xs:hidden sm:hidden md:block lg:block min-h-[447px]">
        <Swiper
          {...settings}
          style={{
            //@ts-ignore
            "--swiper-pagination-color": "#FE5C46",
            "--swiper-pagination-bullet-inactive-color": "#d0d0d0",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            // '--swiper-pagination-bullet-size': '16px',
            "--swiper-pagination-bullet-border-radius": "1px",
            "--swiper-pagination-bullet-horizontal-gap": "6px",
            "--swiper-pagination-bullet-width": "100px",
            "--swiper-pagination-bullet-height": "4.6px",
          }}
          wrapperClass="mb-12"
        >
          {bannArray
            ?.filter((k) => k.type === "top")
            .map((item, i) => (
              <SwiperSlide key={i}>
                <Link href={`${item.handle}`}>
                  <Image
                    unoptimized
                    fetchpriority="high"
                    width={1950}
                    height={550}
                    src={item.web_image + "?tr=w-1950,h-550,c-at_max"}
                    className="aspect-[16/10] md:aspect-auto object-coverxx"
                    alt={`${item.title}`}
                  />
                </Link>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
      <div className="xs:block sm:block md:hidden lg:hidden">
        <Swiper
          {...settings}
          style={{
            //@ts-ignore
            "--swiper-pagination-color": "#FE5C46",
            "--swiper-pagination-bullet-inactive-color": "#d0d0d0",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            // '--swiper-pagination-bullet-size': '16px',
            "--swiper-pagination-bullet-border-radius": "1px",
            "--swiper-pagination-bullet-horizontal-gap": "4px",
            "--swiper-pagination-bullet-width": "50px",
            "--swiper-pagination-bullet-height": "3.6px",
          }}
          wrapperClass="mb-8"
        >
          {bannArray
            ?.filter((k) => k.type === "top")
            .map((item, i) => (
              <SwiperSlide key={i}>
                <Link href={`${item.handle}`}>
                  <Image
                    unoptimized
                    fetchpriority="high"
                    width={511}
                    height={760}
                    src={item.mobile_image + "?tr=w-511,h-760,c-at_max"}
                    className=""
                    alt={`${item.title}`}
                  />
                </Link>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
};

export default HomeBanner;
