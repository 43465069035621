import dynamic from "next/dynamic";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { LicensedStores } from "@/components/LicensedStores";
import HomeBanner from "@/components/HomeBanner";
import ProductsHome from "@/components/ProductsHome";
import SuperFavHome from "@/components/SuperFavHome";
import { useRouter } from 'next/router';

import { initalizeTractEvents } from "../analytics-utils/eventTracker";
import { useDispatch } from "react-redux";
import {
  CONST_BANNER,
  CONST_FEATURED,
  CONST_BOD,
  CONST_DEVICE_BRAND,
  CONST_TRENDING,
} from "@/utils/constants";
import SpotlightBanner from "@/components/SpotlightBanner";
import { fetchLocation } from "@/store/locationSlice";
import Head from "next/head";

//import SliderV1 from "@/components/SliderV1";

const Spinner = dynamic(() => import("@/components/spinner"));
// const HeroBanner = dynamic(() => import("@/components/HeroBanner"));
const Wrapper = dynamic(() => import("@/components/Wrapper"));
const NewInHome = dynamic(() => import("@/components/NewInHome"));
// const DevicesHome = dynamic(() => import("@/components/DevicesHome"));
const Devices = dynamic(() => import("@/components/Devices"));
const OneOfOneBanner = dynamic(() => import("@/components/OneOfOneBanner"));
const BestsellerHome = dynamic(() => import("@/components/BestsellerHome"));

export default function Home(props) {
  
  const router = useRouter();
  
  const [isloading, setIsloading] = useState(false);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   const GetSuperFav = async () => {
  //     try {
  //       const banner = await fetch(CONST_BANNER);
  //       const res = await banner.json();
  //       setBannArray(res.data);
  //     } catch (ex) {
  //       console.log(ex);
  //     }
  //   };
  //   GetSuperFav();
  // }, []);

  useEffect(() => {
    dispatch(fetchLocation());
  }, [dispatch]);
  const changeLoadingStatus = (st) => {
    setIsloading(st);
  };
  useEffect(() => {
    try {
      //
      // initalizeTractEvents(true);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      <Head>
        <title>
          Macmerise - Tell your story through our products and innovation | Buy
          Perfect gifts Online to celebrate moments.
        </title>
        <meta
          name="description"
          content="Explore Macmerise for AI-designed mobile accessories and officially licensed products, including unique skins, cases, and more. Elevate your lifestyle with exclusive designs and top-quality products today!"
        />
      </Head>
      <main className="overflow-x-hidden">
        <HomeBanner bannArray={props.bannArray} />
        <Wrapper>
          <SuperFavHome supfavarray={props.supfavarray} />
          <NewInHome bestSeller={props.bestseller} />
        </Wrapper>
        <SpotlightBanner bannArray={props.bannArray} />
        <Wrapper>
          <Devices
            changeLoadingStatus={changeLoadingStatus}
            devicebrand={props.devicebrand}
          />
          <ProductsHome bannArray={props.bannArray} />
        </Wrapper>
        <OneOfOneBanner bannArray={props.bannArray} />
        <Wrapper>
          <BestsellerHome bestSeller={props.bestseller} />
          <LicensedStores />
        </Wrapper>
        {isloading ? (
          <div className="flex w-full flex-wrap justify-center">
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 w-full xs:gap-[0.1rem] sm:gap-[0.1rem] md:gap-5 lg:gap-5 gap-5 my-10 md:px-0">
              <Spinner />
            </div>
          </div>
        ) : (
          ""
        )}
      </main>
    </>
  );
}
let bannArray = null,
  bestseller = null,
  devicebrand = null,
  supfavarray = null,
  cacheTimestamp = null;
export async function getServerSideProps(context) {
  // Fetch data from external API
  const currentTime = Date.now();
  let is_api_hit = false;
  const { req } = context;
  try {
    //if (!bannArray || (currentTime - cacheTimestamp > process.env.CACHE_EXPIRY_DURATION)) {
    const banner = await fetch(CONST_BANNER, {
      next: { revalidate: process.env.REVALIDATE_API_TIMING },
    });
    const res = await banner.json();
    bannArray = res.data;
    // console.log("Banner api called");
    is_api_hit = true;
    //}

    const token = req.cookies.mmtoken;
    // console.log("req.cookies.mmtoken",req.cookies.mmtoken);
    let config = "";
    if (token || token !== undefined) {
      config = {
        headers: {
          Authorization: token,
        },
      };
    } else {
      config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        next: { revalidate: process.env.REVALIDATE_API_TIMING },
      };
    }
    //featured product
    //if (!bestseller || (currentTime - cacheTimestamp > process.env.CACHE_EXPIRY_DURATION)) {
    const trending = await fetch(CONST_FEATURED, config);
    const restrending = await trending.json();
    bestseller = restrending.data;
    is_api_hit = true;
    //}
    // const ls = await fetch(CONST_BOD + "?type=brand");
    // const resbrand = await ls.json();
    // const brands=res.data;
    //if (!devicebrand || (currentTime - cacheTimestamp > process.env.CACHE_EXPIRY_DURATION)) {
    const brandresponse = await fetch(CONST_DEVICE_BRAND, {
      next: { revalidate: process.env.REVALIDATE_API_TIMING },
    });
    const resdevicebrand = await brandresponse.json();
    devicebrand = resdevicebrand.data;
    is_api_hit = true;
    //}
    //if (!supfavarray || (currentTime - cacheTimestamp > process.env.CACHE_EXPIRY_DURATION)) {
    const superfav = await fetch(CONST_TRENDING, {
      next: { revalidate: process.env.REVALIDATE_API_TIMING },
    });
    const superfavres = await superfav.json();
    supfavarray = superfavres.data;
    is_api_hit = true;
    //}

    if (is_api_hit) cacheTimestamp = currentTime;
  } catch (error) {
    console.log(error);
  }
  // Pass data to the page via props
  return { props: { bannArray, bestseller, devicebrand, supfavarray } };
}
